import React from "react";

function Footer() {
  return (
    <footer className="footer has-background-white-ter">
      <div className="content has-text-centered has-text-black_ter">
        <p>Construit par <strong>Clément Tabouelle.</strong></p>
        <p class="is-size-7">(Avec parfois un peu d'aide, merci aux concerné(e)s.)</p>
      </div>
    </footer>
  );
}

export default Footer;
