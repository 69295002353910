import React from "react";

import TimelineProjects from "../elements/TimelineProjects";

function Projects() {
  return (
    <section className="section" id="projects">
      <div className="container">
        <h1 className="title">Projets personnels</h1>
        <TimelineProjects />
      </div>
    </section>
  );
}

export default Projects;